import {clientRequest} from '../apiClient';

type ContactInfo = {
  email: string;
  language: string;
};

const signUpForNewsletter = (body: ContactInfo) => {
  return clientRequest({url: '/forms/newsletter-signup', options: {method: 'POST'}, body});
};

export default signUpForNewsletter;
