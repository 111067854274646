import React from 'react';
import styled from 'styled-components';
import {FormikErrors, FormikTouched} from 'formik';
import {errorColor} from '../utils/constants';

const InputContainer = styled.div`
  margin-bottom: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0 2px 20px;
`;

const HelperText = styled.span`
  font-size: 13px;
  margin-bottom: 4px;
  color: var(--gray-text);
  line-height: 1.2;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
`;

type Props = {
  brandStyle: BrandStyle;
  helperText?: string;
  errorMessage?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  setTouched?: Function;
  children: React.ReactNode;
  focused?: boolean;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
  style?: React.CSSProperties;
};

const ModernInputWrapper = ({brandStyle, helperText, errorMessage, children, focused, touched, style}: Props) => {
  return (
    <InputContainer style={style}>
      <InputWrapper>{children}</InputWrapper>
      <TextContainer>
        {helperText && <HelperText>{helperText}</HelperText>}
        {errorMessage && touched ? <HelperText style={{color: errorColor}}>{String(errorMessage)}</HelperText> : null}
      </TextContainer>
    </InputContainer>
  );
};

export default ModernInputWrapper;
