import {useState, useEffect} from 'react';

const useFocus = (ref, defaultState = false) => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (!ref.current) return;

    const onFocus = () => setState(true);
    const onBlur = () => setState(false);

    ref.current.addEventListener('focus', onFocus);
    ref.current.addEventListener('blur', onBlur);

    return () => {
      if (!ref.current) return;

      ref.current.removeEventListener('focus', onFocus);
      ref.current.removeEventListener('blur', onBlur);
    };
  }, [ref.current]);

  return state;
};

export default useFocus;
