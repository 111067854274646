export {default as touchpointIcon} from 'public/icons/touchpointIcon.svg';

export {default as widgetIcon} from 'public/icons/widgetIcon.svg';

export {default as dashboardLine} from 'public/icons/dashboardIcon.svg';

export {default as sidebarIconActive} from 'public/icons/sidebarIconActive.svg';

export {default as sidebarIcon} from 'public/icons/sidebarIcon.svg';

export {default as panelistaBot} from 'public/icons/panelistaBot.svg';
