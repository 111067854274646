import * as Yup from 'yup';
import {useState} from 'react';
import {useFormik} from 'formik';
import styled from 'styled-components';
import {useTranslations} from 'next-intl';
import {LandingBigSecondaryButton, SmallSectionTitle} from 'src/components/landingStyledComponents';
import {ThankYouLayoutContainer} from 'src/components/pagesStyles';
import ModernTextInput from 'src/components/ModernTextInput';
import {lightBackgroundColor} from 'src/utils/constants';
import contactForm from 'src/client/methods/contactForm';
import getBrandStyle from 'src/utils/getBrandStyle';
import ClapImage from '../../icons/clapImage.svg';

const SuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 300px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ModalColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const WebinarImage = styled.div`
  width: 360px;
  flex-shrink: 0;
  border-radius: 8px;
  margin: 6px 6px 6px 40px;
  background-size: cover;
  background-position: center;
  background-image: url('images/webinarPhoto.jpg');

  @media (max-width: 800px) {
    display: none;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  max-width: 950px;
  padding: 40px;
  display: flex;
  font-size: 18px;

  @media (max-width: 400px) {
    padding: 20px;
  }
`;

const initialValues = {name: '', email: ''};

const formType = 'webinar';

const getFormSchema = (t: any) =>
  Yup.object().shape({
    name: Yup.string().max(50, t('common.too long')).required(t('common.required')),
    email: Yup.string()
      .email(t('website.must be valid email'))
      .max(100, t('common.too long'))
      .required(t('common.required'))
  });

const WebinarModalContent = () => {
  const t = useTranslations();
  const [isSent, setSent] = useState(false);

  const onSubmit = (values: any) => {
    const body = {...values, type: formType};

    return contactForm(body).then(() => {
      setSent(true);
    });
  };

  const brandStyle = getBrandStyle();

  const formik = useFormik({validationSchema: getFormSchema(t), initialValues, onSubmit});

  return (
    <ModalContent>
      <ModalColumn>
        {isSent ? (
          <SuccessContainer>
            <ThankYouLayoutContainer id="newsletter-signup-success">
              <ClapImage viewBox="0 0 80 80" style={{width: 120, height: 120}} />
              <h2>Härligt</h2>
            </ThankYouLayoutContainer>
          </SuccessContainer>
        ) : (
          <>
            <SmallSectionTitle style={{textAlign: 'center'}}>
              Webinar: Sluta gissa! - Agila kundinsikter i praktiken
            </SmallSectionTitle>

            <p style={{textAlign: 'center'}}>Hur involverar man sina kunder en helt vanlig torsdag?</p>

            <form style={{marginTop: 40}} id="webinar-form">
              <ModernTextInput
                inputStyle={{backgroundColor: lightBackgroundColor}}
                formikProps={formik}
                brandStyle={brandStyle}
                id="name"
                placeholder={t('common.first name')}
              />

              <ModernTextInput
                inputStyle={{backgroundColor: lightBackgroundColor}}
                formikProps={formik}
                brandStyle={brandStyle}
                id="email"
                placeholder={t('common.email')}
              />

              <LandingBigSecondaryButton
                style={{width: '100%'}}
                as="button"
                type="submit"
                id="webinar-form"
                onClick={formik.submitForm}
              >
                {t('common.send')}
              </LandingBigSecondaryButton>
            </form>
          </>
        )}
      </ModalColumn>
      <WebinarImage />
    </ModalContent>
  );
};

export default WebinarModalContent;
