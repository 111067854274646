'use client';

import {useLocale} from 'next-intl';
import styled from 'styled-components';
import LandingNewsletterForm from './LandingNewsletterForm';
import {panelistaLightColor} from 'src/utils/constants';
import {WidthContainer} from './LandingComponents';

const compactThreshold = 900;

export const Container = styled(WidthContainer)`
  margin-top: 100px;
  margin-bottom: 100px;

  @media (max-width: ${compactThreshold}px) {
    margin-top: 40px;
    margin-bottom: 60px;
    padding: 0 var(--side-padding);
  }
`;

export const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 70px 40px 60px 40px;
  background-color: ${panelistaLightColor};
  overflow: hidden;

  @media (max-width: 600px) {
    padding: 40px var(--side-padding) var(--side-padding) var(--side-padding);
  }
`;

const Illustration = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/penIllustration.svg');
  background-size: 500px;
  background-position: right -40px center;
  background-repeat: no-repeat;
`;

export const StyledTitle = styled.h3`
  position: relative;
  font-family: var(--font-jakarta);
  font-weight: 700;
  text-align: center;
  font-size: 32px;
  letter-spacing: -0.02em;
  max-width: 820px;
  line-height: 1.4;
  margin-bottom: 30px;

  @media (max-width: ${compactThreshold}px) {
    font-size: 24px;
  }

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const LandingNewsletterSection = () => {
  const locale = useLocale();

  if (locale !== 'sv') return null;

  return (
    <Container>
      <Box>
        <Illustration />

        <StyledTitle>
          Få ett inspirerande mail varje månad om kundcentrerade arbetssätt och snabba feedbackloopar
        </StyledTitle>

        <LandingNewsletterForm />
      </Box>
    </Container>
  );
};

export default LandingNewsletterSection;
