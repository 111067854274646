'use client';

import styled from 'styled-components';
import {useTranslations} from 'next-intl';
import {SectionTitleLegacy} from '../landingStyledComponents';
import {WidthContainer} from './LandingComponents';

const StyledContainer = styled(WidthContainer)`
  padding-bottom: 120px;

  @media (max-width: 800px) {
    padding-bottom: 90px;
  }
`;

const GradientsContainer = styled.div`
  padding-top: 40px;
  width: 100%;
  background-image: radial-gradient(70% 50% at -10% 90%, rgba(243, 183, 44, 0.1) 0%, rgba(243, 183, 44, 0) 100%),
    radial-gradient(60% 80% at 100% 90%, rgba(29, 146, 153, 0.2) 0%, rgba(29, 146, 153, 0) 100%);
`;

const Description = styled.p`
  max-width: 760px;
  font-size: 20px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const LandingAboutUsSection = () => {
  const t = useTranslations();

  return (
    <GradientsContainer>
      <StyledContainer>
        <SectionTitleLegacy>{t('home.about us title')}</SectionTitleLegacy>

        <Description>{t.rich('home.about us text', {br: () => <br />})}</Description>
      </StyledContainer>
    </GradientsContainer>
  );
};

export default LandingAboutUsSection;
