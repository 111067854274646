'use client';

import styled from 'styled-components';
import {useTranslations} from 'next-intl';
import {highlightColor} from 'src/utils/constants';
import {WidthContainer} from './LandingComponents';
import RemixIcon from '../RemixIcon';

const StyledContainer = styled(WidthContainer)`
  margin: 80px auto;
  display: flex;
  justify-content: space-around;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const InfoGroup = styled.div`
  justify-content: space-around;
  flex-grow: 1;
  display: flex;

  @media (max-width: 900px) {
    &:first-child {
      margin-bottom: 40px;
    }
  }
`;

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  max-width: 280px;
  font-weight: 600;
  font-size: 14px;
  margin: 0 5px;
  width: 1px;
  flex-grow: 1;

  span {
    max-width: 170px;
  }

  @media (max-width: 900px) {
    flex-direction: column;

    span {
      text-align: center;
    }
  }

  @media (max-width: 450px) {
    font-size: 13px;

    span {
      max-width: 150px;
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  flex-shrink: 0;
  background-color: ${highlightColor};
  margin-right: 16px;

  @media (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const iconColor = 'rgba(0,0,0,0.7)';

const LandingDataProtectionSection = () => {
  const t = useTranslations();

  return (
    <StyledContainer className="data-protection-strip">
      <InfoGroup>
        <InfoBox>
          <IconContainer>
            <RemixIcon name="map-pin-line" color={iconColor} />
          </IconContainer>
          <span>{t('website.based in sweden')}</span>
        </InfoBox>

        <InfoBox>
          <IconContainer>
            <RemixIcon name="shield-check-line" color={iconColor} />
          </IconContainer>
          <span>{t('website.gdpr compliant')}</span>
        </InfoBox>
      </InfoGroup>

      <InfoGroup>
        <InfoBox>
          <IconContainer>
            <RemixIcon name="lock-password-line" color={iconColor} />
          </IconContainer>
          <span>{t('website.data is encrypted')}</span>
        </InfoBox>

        <InfoBox>
          <IconContainer>
            <RemixIcon name="star-line" color={iconColor} />
          </IconContainer>
          <span>{t('website.data is stored in eu')}</span>
        </InfoBox>
      </InfoGroup>
    </StyledContainer>
  );
};

export default LandingDataProtectionSection;
