import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/BaseButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingAboutUsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingArticlesSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingCoachSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingDataProtectionSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingFeedbackAnalysisSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingFooter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingHeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingNewsletterSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingPanelsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingPricingSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingTouchpointsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingUISection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landing/LandingUseCasesSection.tsx");
