'use client';

import styled from 'styled-components';
import {useLocale, useTranslations} from 'next-intl';
import {
  HandWriting,
  SectionFeatureRightSide,
  SectionTextSide,
  SectionTitleLegacy,
  TextSideContent
} from '../landingStyledComponents';
import {LandingPill, WidthContainer} from './LandingComponents';
import DrawnArrow from '../../../public/images/drawnArrow.svg';
import {panelistaPrimaryColor} from 'src/utils/constants';
import RealWidget from '../touchpoints/RealWidget';
import LandingButton from './LandingButton';

const StyledContainer = styled(WidthContainer)`
  display: flex;
  min-height: 700px;

  @media (max-width: 700px) {
    flex-direction: column-reverse;
  }
`;

const Circles = styled.div`
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;

  @media (max-width: 450px) {
    transform: scale(0.7);
    transform-origin: center;
  }
`;

const CircleShape = styled.div<{size: number}>`
  position: absolute;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size}px;
  left: calc(50% - ${(props) => props.size / 2}px);
  top: calc(50% - ${(props) => props.size / 2}px);
  background-color: ${panelistaPrimaryColor};
  opacity: 0.1;
`;

const StyledDrawnArrow = styled(DrawnArrow)`
  position: absolute;
  top: -100px;
  right: 7%;
  transform: rotate(10deg);
`;

const StyledHandwriting = styled(HandWriting)`
  position: absolute;
  top: -135px;
  right: -6%;
  transform: rotate(-6deg);
  color: ${panelistaPrimaryColor};

  @media (max-width: 430px) {
    right: 0;
  }
`;

const LandingTouchpointsSection = () => {
  const t = useTranslations();
  const locale = useLocale();

  const touchpointId = locale === 'sv' ? '616ea8cb8fb3390016432758' : '616eac328b7b9f00161851a4';

  return (
    <div className="bg-[--panelista-light-color]">
      <StyledContainer>
        <SectionTextSide>
          <TextSideContent>
            <LandingPill>{t('common.touchpoint', {count: 2})}</LandingPill>
            <SectionTitleLegacy>{t('home.touchpoints section title')}</SectionTitleLegacy>

            <p>{t.rich('home.touchpoints section body', {strong: (chunks) => <strong>{chunks}</strong>})}</p>

            <LandingButton href="/touchpoints" className="mt-7">
              {t('home.touchpoints section cta')}
            </LandingButton>
          </TextSideContent>
        </SectionTextSide>

        <SectionFeatureRightSide>
          <div className="relative">
            <Circles>
              <CircleShape size={380} />
              <CircleShape size={300} />
              <CircleShape size={220} />
            </Circles>

            <div className="relative">
              <RealWidget touchpointId={touchpointId} />
            </div>

            <StyledDrawnArrow />
            <StyledHandwriting>{t('website.try it out')}</StyledHandwriting>
          </div>
        </SectionFeatureRightSide>
      </StyledContainer>
    </div>
  );
};

export default LandingTouchpointsSection;
