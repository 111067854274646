'use client';

import React from 'react';
import Image from 'next/image';
import {useTranslations} from 'next-intl';
import RemixIcon from 'src/components/RemixIcon';
import {panelistaBot} from 'src/utils/icons';
import LandingButton from './LandingButton';

const LandingPricingSection = () => {
  const t = useTranslations();

  const onBookDemoClick = () => plausible('Book Demo button clicked', {props: {place: 'Pricing section'}});

  const appList = [
    {
      label: t('pricing.one panel and touchpoints'),
      iconName: 'bubble-chart-line'
    },
    {
      label: t('pricing.unlimited questions and answers'),
      iconName: 'infinity-line'
    },
    {
      label: t('pricing.pricing admins feature'),
      iconName: 'user-line'
    },
    {
      label: t('pricing.multiple types of questions'),
      iconName: 'list-indefinite'
    },
    {
      label: t('pricing.pricing ai analysis'),
      icon: panelistaBot,
      iconSize: 20
    },
    {
      label: t('pricing.pricing reports'),
      iconName: 'keynote-line'
    },
    {
      label: t('pricing.own branding'),
      iconName: 'palette-line'
    }
  ];

  const expertsList = [
    {label: t('pricing.pricing experts feature 1')},
    {label: t('pricing.pricing experts feature 2')},
    {label: t('pricing.pricing experts feature 3')},
    {label: t('pricing.pricing experts feature 4')},
    {label: t('pricing.pricing experts feature 5')},
    {label: t('pricing.pricing experts feature 6')},
    {label: t('pricing.pricing experts feature 7')}
  ];

  return (
    <div
      id="pricing"
      className="bg-[--panelista-light-color] pb-16 bg-[radial-gradient(50vw_100%_at_30vw_100%,rgba(29,146,153,0.3)_0%,rgba(29,146,153,0)_100%)]"
    >
      <div className="w-full max-w-[1400px] px-[--side-padding] mx-auto pt-10 flex flex-col items-center">
        <h2 className="landing-section-header mb-4 w-full text-center max-[500px]:text-left">
          {t('pricing.pricing section title')}
        </h2>

        <p className="w-full whitespace-pre-line text-xl max-w-[720px] text-center mb-16 max-[700px]:text-lg max-[700px]:mb-10 max-[500px]:text-left max-[500px]:text-base max-[500px]:mb-7">
          {t('pricing.pricing section subtitle')}
        </p>

        <div className="bg-white rounded-3xl w-full flex mb-10 shadow-[0px_8px_40px_rgba(1,101,106,0.1),0px_3px_7px_rgba(1,101,106,0.05)] max-[800px]:flex-col">
          <div className="w-1/2 p-10 flex flex-col gap-2 max-[800px]:w-full max-[600px]:p-6">
            <h2 className="font-heading font-bold">{t('pricing.pricing digital service')}</h2>

            <p className="text-base/snug max-w-[520px] text-[--gray-text]">
              {t('pricing.pricing digital service description')}
            </p>

            <div className="w-full h-px bg-[--border-color] mt-5" />

            <ul className="mt-5 flex flex-col gap-4">
              {appList.map((item) => (
                <li key={item.label} className="text-lg flex gap-4 max-[500px]:text-base">
                  <div className="w-[36px] h-[36px] rounded-full bg-[--panelista-color] shadow-[0px_3px_20px_rgba(31,131,136,0.2)] flex items-center justify-center shrink-0">
                    {item.iconName || item.icon ? (
                      <RemixIcon name={item.iconName} icon={item.icon} color="white" size={item.iconSize || 18} />
                    ) : null}
                  </div>

                  <span className="mt-1">{item.label}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="w-1/2 bg-[--panelista-dark-color] rounded-3xl text-white p-10 flex flex-col gap-2 max-[800px]:w-full max-[600px]:p-6">
            <h2 className="font-heading font-bold">{t('pricing.pricing experts')}</h2>

            <p className="text-base/snug max-w-[520px] text-[rgba(255,255,255,0.7)]">
              {t('pricing.pricing experts description')}
            </p>

            <div className="w-full h-px bg-[rgba(255,255,255,0.15)] mt-5" />

            <ul className="mt-5 flex flex-col gap-4">
              {expertsList.map((item) => (
                <li key={item.label} className="text-lg flex gap-4 text-white max-[500px]:text-base">
                  <div className="w-[36px] h-[36px] rounded-full bg-[rgba(31,131,136,0.4)] shadow-[0px_3px_20px_rgba(31,131,136,0.2)] border border-solid border-[rgba(255,255,255,0.05)] flex items-center justify-center shrink-0">
                    <RemixIcon name="check-line" color="white" size={20} />
                  </div>

                  <span className="mt-1">{item.label}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-16 mb-12 flex items-center gap-10 max-[800px]:flex-col max-[800px]:my-6">
          <p className="font-heading text-3xl font-extrabold">{t('pricing.price per month')}</p>

          <LandingButton onClick={onBookDemoClick} href="/demo" className="gap-3">
            <div className="w-8 h-8 relative rounded-full overflow-hidden">
              <Image src="/images/charlottaAvatar.jpg" alt="Charlotta" sizes="50px" fill />
            </div>
            <span>{t('website.book demo')}</span>
          </LandingButton>
        </div>
      </div>
    </div>
  );
};

export default LandingPricingSection;
