import React from 'react';
import Link from 'next/link';
import {memoize} from 'lodash';
import styled from 'styled-components';

const getStyleFromSizeFunction = (size?: 'small' | 'large'): string => {
  switch (size) {
    case 'small': {
      return `
        padding: 0 10px;
        border-radius: 4px;
        height: 30px;
      `;
    }

    case 'large': {
      return `
        padding: 0 24px;
        height: 48px;
        font-size: 18px;
      `;
    }

    default: {
      return '';
    }
  }
};

const getStyleFromSize = memoize(getStyleFromSizeFunction);

const StyledButton = styled.button<{size?: 'small' | 'large'}>`
  position: relative;
  background-color: transparent;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: var(--brand-radius);
  border: none;
  font-size: var(--button-text);
  font-weight: 500;
  padding: 0 16px;
  height: 38px;
  text-decoration: none;
  user-select: none;
  transition: transform 0.1s, opacity 0.2s;
  line-height: 1;

  ${(props) => getStyleFromSize(props.size)}

  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }

  &.focus-visible {
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: 2px solid rgba(0, 0, 0, 0.1);
      border-radius: var(--brand-radius);
    }
  }
`;

export type ButtonProps = {
  children?: React.ReactNode;
  // TODO: Fix this type
  // onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onClick?: any;
  style?: React.CSSProperties;
  type?: string;
  size?: 'small' | 'large';
  as?: any;
  disabled?: boolean;
  href?: UrlType;
  target?: string;
  className?: string;
  id?: string;
  layout?: 'size' | 'position' | boolean;
  ariaLabel?: string;
};

const Button = ({
  children,
  onClick,
  style,
  type,
  size,
  as,
  disabled,
  href,
  target,
  className,
  id,
  layout,
  ariaLabel
}: ButtonProps) => {
  const disabledStyle = disabled ? {opacity: 0.5, cursor: 'not-allowed'} : null;

  if (href && !target) {
    return (
      <StyledButton
        as={Link}
        href={href}
        forwardedAs={as}
        id={id}
        size={size}
        style={{...style, ...disabledStyle}}
        className={className}
        onClick={disabled ? undefined : onClick}
        tabIndex={0}
        aria-label={ariaLabel}
      >
        {children}
      </StyledButton>
    );
  }

  return (
    <StyledButton
      id={id}
      as={target ? 'a' : as}
      layout={layout}
      target={target}
      href={href}
      size={size}
      type={type}
      className={className}
      style={{...style, ...disabledStyle}}
      onClick={disabled ? undefined : onClick}
      tabIndex={0}
      aria-label={ariaLabel}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
