import * as Yup from 'yup';
import {Formik} from 'formik';
import {useState} from 'react';
import styled from 'styled-components';
import {useLocale, useTranslations} from 'next-intl';
import signUpForNewsletter from 'src/client/methods/signUpForNewsletter';
import {LandingBigButton} from '../landingStyledComponents';
import getBrandStyle from 'src/utils/getBrandStyle';
import ModernTextInput from '../ModernTextInput';

const FormContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledForm = styled.div`
  flex-basis: 320px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    flex-basis: auto;

    & > * {
      width: 100%;
    }
  }
`;

const SubscribedSectionContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 56px;

  p {
    color: var(--dark-text);
    text-align: center;
    width: 100%;
  }
`;

const getFormSchema = (t: any) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('website.must be valid email'))
      .max(100, t('common.too long'))
      .required(t('common.required'))
  });

const brandStyle = getBrandStyle();

type FormType = {
  email: string;
};

const LandingNewsletterForm = () => {
  const t = useTranslations();
  const locale = useLocale();
  const [isSent, setIsSent] = useState(false);

  const formSchema = getFormSchema(t);

  const onSubmit = (formValues: FormType) => {
    const body = {...formValues, language: locale};

    return signUpForNewsletter(body).then(() => {
      setIsSent(true);
      plausible('Subscribed for newsletter');
    });
  };

  return (
    <FormContainer>
      {isSent ? (
        <SubscribedSectionContainer>
          <p>{t('home.signed up for newsletter body')}</p>
        </SubscribedSectionContainer>
      ) : (
        <Formik validationSchema={formSchema} initialValues={{email: ''}} onSubmit={onSubmit}>
          {(formikProps) => {
            const {isSubmitting, submitForm} = formikProps;

            return (
              <StyledForm id="newsletter-form">
                <ModernTextInput
                  formikProps={formikProps}
                  brandStyle={brandStyle}
                  type="email"
                  id="email"
                  placeholder={t('common.email')}
                />
                <div style={{width: 16}} />
                <LandingBigButton
                  disabled={isSubmitting}
                  as="button"
                  type="submit"
                  id="submit-newsletter-form"
                  onClick={isSubmitting ? null : submitForm}
                >
                  {t('home.sign up for newsletter')}
                </LandingBigButton>
              </StyledForm>
            );
          }}
        </Formik>
      )}
    </FormContainer>
  );
};

export default LandingNewsletterForm;
