'use client';

import {useState} from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import {useInView} from 'react-intersection-observer';
import {SectionTitleLegacy} from '../landingStyledComponents';
import {WidthContainer} from './LandingComponents';
import LandingTabBar from './LandingTabBar';

const ReactPlayer = dynamic(() => import('react-player'), {ssr: false});

const StyledWidthContainer = styled(WidthContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-height: 990px) {
    max-width: 1200px;
  }

  @media (max-height: 870px) {
    max-width: 1050px;
  }

  @media (max-height: 730px) {
    max-width: 900px;
  }

  @media (max-height: 630px) {
    max-width: 800px;
  }
`;

const Title = styled(SectionTitleLegacy)`
  @media (max-width: 500px) {
    display: none;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  color: white;
  max-width: 720px;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

const ScreenContainer = styled.div`
  background-color: white;
  border-radius: 20px;
  width: 100%;
  padding-bottom: 65.2%;
  margin-top: 60px;
  margin-bottom: 80px;
  box-shadow: 0px 14px 54px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;
  overflow: hidden;

  video {
    object-fit: cover;
  }

  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }

  @media (max-width: 700px) {
    margin-top: 40px;
  }

  @media (max-width: 500px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

type UITabContent = {
  key: string;
  tabTitle: string;
  title: string;
  description: string;
  videoUrl: string;
};

type Props = {
  bottomBackgroundColor?: string;
  uiContents: UITabContent[];
  analyticsPlace?: string;
};

const LandingUISection = ({bottomBackgroundColor = 'white', uiContents, analyticsPlace}: Props) => {
  const {ref, inView} = useInView();
  const [activeIndex, setActiveIndex] = useState(0);

  const activeContent = uiContents[activeIndex];

  const setIndex = (index: number) => {
    setActiveIndex(index);
    plausible('Browsing UI animations', {props: {place: analyticsPlace}});
  };

  return (
    <div
      style={{'--bottom-bg-color': bottomBackgroundColor} as React.CSSProperties}
      className="bg-[--panelista-dark-color] flex flex-col text-white relative items-center after:content-[''] after:bg-[--bottom-bg-color] after:absolute after:z-0 after:bottom-0 after:w-full after:h-[34%] max-[1000px]:after:h-[30%] max-[500px]:after:h-[22%]"
    >
      <LandingTabBar
        id="ui-animations"
        tabs={uiContents.map((c) => ({text: c.tabTitle}))}
        setActiveIndex={setIndex}
        activeIndex={activeIndex}
      />

      <StyledWidthContainer>
        <Title>{activeContent.title}</Title>

        <Subtitle>{activeContent.description}</Subtitle>

        <ScreenContainer ref={ref}>
          <ReactPlayer
            playing={inView}
            url={activeContent.videoUrl}
            height="101%"
            width="100%"
            style={{position: 'absolute'}}
            loop
            muted
            playsinline
          />
        </ScreenContainer>
      </StyledWidthContainer>
    </div>
  );
};

export default LandingUISection;
