'use client';

import {useState} from 'react';
import Image from 'next/image';
import {useTranslations} from 'next-intl';
import {twJoin, twMerge} from 'tailwind-merge';
import {AnimatePresence, motion} from 'framer-motion';
import {SectionTitle, WidthContainer} from './LandingComponents';
import summaries1 from '../../../public/images/summaries_1.png';
import summaries2 from '../../../public/images/summaries_2.png';
import findings1 from '../../../public/images/findings_1.png';
import findings2 from '../../../public/images/findings_2.png';
import reports1 from '../../../public/images/reports_1.png';
import BaseButton from '../BaseButton';

const getSections = (t: (key: string) => string) => [
  {
    id: 'findings',
    title: t('home.analysis findings title'),
    description: t('home.analysis findings description')
  },
  {
    id: 'summaries',
    title: t('home.analysis summaries title'),
    description: t('home.analysis summaries description')
  },
  {
    id: 'reports',
    title: t('home.analysis reports title'),
    description: t('home.analysis reports description')
  }
];

const ease = [0.25, 1, 0.5, 1];

const firstItemMotionProps = {
  initial: {opacity: 0, y: 200},
  animate: {opacity: 1, y: 0},
  exit: {opacity: 0, y: 200, transition: {delay: 0.02, duration: 0.6, ease}},
  transition: {delay: 0, duration: 0.6, ease}
};

const secondItemMotionProps = {
  initial: {opacity: 0, y: 200},
  animate: {opacity: 1, y: 0},
  exit: {opacity: 0, y: 200, transition: {delay: 0, duration: 0.6, ease}},
  transition: {delay: 0.06, duration: 0.6, ease}
};

type ShowcaseProps = {
  activeSection: string;
  className?: string;
};

const ShowcaseWindow = ({activeSection, className}: ShowcaseProps) => {
  return (
    <div
      className={twMerge(
        'w-0 grow-[1.2] max-w-[686px] rounded-[22px] max-[700px]:rounded-2xl bg-[--panelista-dark-color] aspect-[1.15] relative overflow-hidden after:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.06)] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:rounded-[22px] after:max-[700px]:rounded-2xl after:content-[""]',
        className
      )}
    >
      <AnimatePresence mode="popLayout" initial={false}>
        {activeSection === 'findings' ? (
          <div className="w-full h-full relative" key="findings-visual">
            <motion.div {...firstItemMotionProps} className="w-[65.452%] absolute bottom-0 left-[6%]">
              <Image src={findings1} alt="Findings 1" />
            </motion.div>

            <motion.div
              {...secondItemMotionProps}
              className="w-[65.452%] absolute bottom-0 right-[6%] shadow-[0px_3px_48px_rgba(7,47,49,0.5)] rounded-xl"
            >
              <Image src={findings2} alt="Findings 2" />
            </motion.div>
          </div>
        ) : null}

        {activeSection === 'summaries' ? (
          <div className="w-full h-full relative flex justify-center" key="summaries-visual">
            <motion.div {...firstItemMotionProps} className="w-[85.13%] absolute top-[14%]">
              <Image src={summaries1} alt="Summaries 1" />
            </motion.div>

            <motion.div
              {...secondItemMotionProps}
              className="w-[83.82%] absolute bottom-0 right-0 shadow-[0px_3px_48px_rgba(7,47,49,0.5)] rounded-xl"
            >
              <Image src={summaries2} alt="Summaries 2" />
            </motion.div>
          </div>
        ) : null}

        {activeSection === 'reports' ? (
          <div className="w-full h-full relative" key="reports-visual">
            <motion.div {...firstItemMotionProps} className="w-[94.9%] absolute bottom-0 right-0">
              <Image src={reports1} alt="Reports" />
            </motion.div>
          </div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

const LandingFeedbackAnalysisSection = () => {
  const t = useTranslations();

  const [activeSection, setActiveSection] = useState('findings');

  return (
    <WidthContainer className="flex my-24 pb-14 max-[700px]:pb-0 max-[700px]:my-14 gap-10 items-end">
      <div className="w-0 grow flex flex-col">
        <SectionTitle className="[text-wrap:balance] pr-10 max-[1200px]:pr-0 mb-8 max-[700px]:mb-4">
          {t('home.feedback analysis title')}
        </SectionTitle>

        <p className="max-w-[540px] text-xl max-[700px]:text-base">{t('home.feedback analysis description')}</p>

        <div className="flex max-[1000px]:flex-row-reverse gap-10 items-center mt-14 max-[700px]:mt-8 max-[700px]:flex-col">
          <ShowcaseWindow activeSection={activeSection} className="hidden max-[1000px]:flex w-full max-w-[540px]" />

          <div className="flex flex-col gap-2">
            {getSections(t).map((section) => {
              return (
                <BaseButton
                  key={section.id}
                  onClick={() => setActiveSection(section.id)}
                  color={activeSection === section.id ? 'var(--panelista-dark-color)' : 'var(--dark-text)'}
                  className={twJoin(
                    'flex-col gap-2 h-auto text-left items-start px-4 -ml-4 max-[1000px]:px-3 max-[1000px]:-ml-3 max-[700px]:ml-0 py-3 max-w-[540px] hover:bg-[--panelista-light-color] active:bg-[--panelista-light-color]',
                    activeSection === section.id ? 'bg-[--panelista-light-color]' : ''
                  )}
                  variant="text"
                >
                  <h3 className="font-heading text-xl">{section.title}</h3>
                  <p className="font-normal opacity-80 text-current">{section.description}</p>
                </BaseButton>
              );
            })}
          </div>
        </div>
      </div>

      <ShowcaseWindow activeSection={activeSection} className="max-[1000px]:hidden" />
    </WidthContainer>
  );
};

export default LandingFeedbackAnalysisSection;
