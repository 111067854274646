'use client';

import {useState} from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import {AnimatePresence} from 'framer-motion';
import {useLocale, useTranslations} from 'next-intl';
import {LandingBigButton, LandingSubHeading, SectionTitleLegacy} from '../landingStyledComponents';
import WebinarModalContent from 'src/pages/landing-page/WebinarModalContent';
import {mediumGrayText, panelistaLightColor} from 'src/utils/constants';
import {LandingPill, WidthContainer} from './LandingComponents';
import ResponsiveRadixModal from '../ResponsiveRadixModal';
import RemixIcon from '../RemixIcon';

const StyledSubHeading = styled(LandingSubHeading)`
  text-align: left;
`;

const ArticlesRow = styled.div`
  display: flex;
  margin-bottom: 80px;
  margin-right: -16px;
  margin-left: -16px;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    margin-right: -8px;
    margin-left: -8px;
    margin-bottom: 80px;
  }
`;

const ArticleShape = styled.a`
  display: flex;
  flex-basis: 240px;
  flex-direction: column;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 8px 40px 0 rgba(0, 0, 0, 0.1);
  margin-right: 16px;
  margin-left: 16px;
  flex-grow: 1;
  overflow: hidden;
  text-decoration: none;

  @media (max-width: 800px) {
    margin-bottom: 16px;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

const WebinarArticleShape = styled(ArticleShape)`
  padding: 20px;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  background-color: ${panelistaLightColor};
  background-image: radial-gradient(280px 200px at -5% 80%, rgba(243, 183, 44, 0.15) 0%, rgba(243, 183, 44, 0) 100%),
    radial-gradient(300px 160px at 10% 0px, rgba(233, 16, 96, 0.1) 0%, rgba(233, 16, 96, 0) 100%),
    radial-gradient(140px 300px at 100% 50px, rgba(29, 146, 153, 0.2) 0%, rgba(29, 146, 153, 0) 100%);

  @media (max-width: 900px) {
    width: 100%;
    flex-basis: 80%;
    margin-bottom: 32px;
  }

  @media (max-width: 800px) {
    width: 100%;
    flex-basis: 80%;
    margin-bottom: 16px;
  }
`;

const ArticleTitle = styled.h4`
  padding: 32px 16px 0 16px;
  font-family: var(--font-jakarta);
  font-weight: 700;
  font-size: 24px;
  max-width: 320px;
  color: initial;

  @media (max-width: 800px) {
    font-size: 20px;
    padding: 16px 16px 0 16px;
  }
`;

const WebinarTitle = styled(ArticleTitle)`
  text-align: center;
  padding-top: 16px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  background-color: white;
`;

const ReadMoreText = styled.span`
  display: flex;
  align-items: center;
  font-family: var(--font-jakarta);
  font-weight: 700;
  color: ${mediumGrayText};
  padding: 16px 16px 32px 16px;

  .h-icon {
    margin-left: 8px;
  }

  @media (max-width: 800px) {
    padding: 16px;
  }
`;

const WebinarDescription = styled.p`
  text-align: center;
  color: var(--dark-text);
  margin: 16px 0;
`;

const LandingArticlesSection = () => {
  const t = useTranslations();
  const locale = useLocale();
  const [isModalOpened, setModalOpened] = useState(false);

  if (locale !== 'sv') return null;

  const openWebinar = () => {
    setModalOpened(true);
    plausible('Webinar button clicked');
  };

  return (
    <>
      <div>
        <WidthContainer>
          <SectionTitleLegacy style={{marginBottom: 20}}>Kunskap och inspiration</SectionTitleLegacy>

          <StyledSubHeading>Konkreta tips om hur du blir bättre på att jobba kundcentrerat.</StyledSubHeading>

          <ArticlesRow>
            <WebinarArticleShape as="div">
              <LandingPill>Webinar</LandingPill>
              <WebinarTitle>Sluta gissa! - Agila kundinsikter i praktiken</WebinarTitle>
              <WebinarDescription>Hur involverar man sina kunder en helt vanlig torsdag?</WebinarDescription>

              <LandingBigButton as="button" onClick={openWebinar}>
                Se webinar
              </LandingBigButton>
            </WebinarArticleShape>

            <ArticleShape
              href="https://artiklar.panelista.com/sv/35-smarta-fragor-att-stalla-till-dina-kunder"
              target="_blank"
            >
              <ImageContainer>
                <Image
                  src="/images/articleOneImage.jpg"
                  fill
                  sizes="450px"
                  style={{objectFit: 'cover'}}
                  alt="35 smarta frågor att ställa till dina kunder"
                />
              </ImageContainer>
              <ArticleTitle>35 smarta frågor att ställa till dina kunder</ArticleTitle>
              <ReadMoreText>
                {t('website.read more')}
                <RemixIcon name="arrow-right-line" color={mediumGrayText} size={18} />
              </ReadMoreText>
            </ArticleShape>

            <ArticleShape
              href="https://artiklar.panelista.com/sv/sa-far-du-arbetet-med-kundinsikter-att-bli-agilt"
              target="_blank"
            >
              <ImageContainer>
                <Image
                  src="/images/articleTwoImage.jpg"
                  fill
                  sizes="450px"
                  style={{objectFit: 'cover'}}
                  alt="Så får du arbetet med kundinsikter att bli agilt"
                />
              </ImageContainer>
              <ArticleTitle>Så får du arbetet med kundinsikter att bli agilt</ArticleTitle>
              <ReadMoreText>
                {t('website.read more')}
                <RemixIcon name="arrow-right-line" color={mediumGrayText} size={18} />
              </ReadMoreText>
            </ArticleShape>
          </ArticlesRow>
        </WidthContainer>
      </div>

      <AnimatePresence>
        {isModalOpened ? (
          <ResponsiveRadixModal onClose={() => setModalOpened(false)} className="max-w-[900px]">
            <WebinarModalContent />
          </ResponsiveRadixModal>
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default LandingArticlesSection;
