'use client';

import dynamic from 'next/dynamic';
import {useLocale, useTranslations} from 'next-intl';
import {LandingPill, SectionTitle, WidthContainer} from './LandingComponents';

const ReactPlayer = dynamic(() => import('react-player/file'), {ssr: false});

const LandingCoachSection = () => {
  const t = useTranslations();
  const locale = useLocale();

  const videoUrl = `https://panelista.s3.nl-ams.scw.cloud/website/coachVideo_${locale}.mp4`;
  const subtitlesUrl = locale === 'sv' ? `/subtitles/coachVideoSubtitles_sv.vtt` : undefined;

  return (
    <div className="bg-[#F2F8F9] relative">
      <WidthContainer className="flex items-center gap-8 max-[700px]:flex-col">
        <div className="grow w-0 flex justify-center py-[64px] max-[700px]:py-0 max-[700px]:pt-[80px] max-[700px]:w-full overflow-hidden">
          <div className="w-full max-w-[288px] aspect-[0.5680555556] relative flex items-center justify-center">
            <div className="w-[90%] h-[90%] bg-[#bce2e5] rounded-[36px] absolute rotate-[20deg] origin-center opacity-50"></div>
            <div className="w-[95%] h-[94%] bg-[#bce2e5] rounded-[36px] absolute rotate-[10deg] origin-center"></div>
            <div className="w-full h-full rounded-[36px] relative overflow-hidden">
              <ReactPlayer
                url={videoUrl}
                height="100%"
                width="100%"
                playsinline
                controls
                config={{
                  tracks: [
                    {
                      kind: 'captions',
                      label: 'Subtitles',
                      src: subtitlesUrl,
                      srcLang: locale,
                      default: true
                    }
                  ]
                }}
              />
            </div>
          </div>
        </div>

        <div className="py-[100px] max-[700px]:py-0 grow w-0 max-[700px]:w-full flex justify-center">
          <div className="max-w-[440px] max-[700px]:max-w-full flex flex-col items-start gap-6">
            <LandingPill>Coach</LandingPill>
            <SectionTitle>{t('home.coach title')}</SectionTitle>

            <p className="text-xl text-[--dark-text] [&_strong]:font-normal [&_strong]:bg-[--highlight-color] [&_strong]:rounded-[4px] [&_strong]:px-1">
              {t.rich('home.coach description', {strong: (chunks) => <strong>{chunks}</strong>, br: () => <br />})}
            </p>
          </div>
        </div>
      </WidthContainer>
    </div>
  );
};

export default LandingCoachSection;
